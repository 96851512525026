var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Navigation'),_c('div',{staticClass:"pa-5 flex space-between"},[_c('WorkstationFilters'),_c('ElButton',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.$refs.workstationDialog.add()}}},[_vm._v("Adauga Post de Lucru")])],1),_c('ResourceTable',{attrs:{"query":_vm.query,"filter":_vm.filter,"default-sort":{ prop: 'data.name' }}},[_c('ElTableColumn',{attrs:{"type":"index","width":"50"}}),_c('ElTableColumn',{attrs:{"prop":"data.name","label":"Nume","sortable":""}}),_c('ElTableColumn',{attrs:{"label":"Culoare"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{style:({ background: row.data.color, width: '50px' })})}}])}),_c('ElTableColumn',{attrs:{"label":"Creare","sortable":"","sort-by":"data.createdAt.seconds"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return (row.data.createdAt)?_c('TimeAgo',{attrs:{"date":row.data.createdAt.toDate()}}):_vm._e()}}],null,true)}),_c('ElTableColumn',{attrs:{"label":"Actualizare","sortable":"","sort-by":"data.updatedAt.seconds"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return (row.data.updatedAt)?_c('TimeAgo',{attrs:{"date":row.data.updatedAt.toDate()}}):_vm._e()}}],null,true)}),_c('ElTableColumn',{attrs:{"align":"right"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('ElButton',{attrs:{"size":"mini"},on:{"click":function($event){return _vm.$refs.workstationDialog.edit(row.id)}}},[_vm._v("Modifica")])]}}])})],1),_c('WorkstationDialog',{ref:"workstationDialog"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }